@import 'variables';

.spinner-container {
  &.centered-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .spinner-fade-in {
    opacity: 1;
    -webkit-animation-name: fadeInOpacity;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-duration: 1s;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }

  .chr-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .chr-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 7px solid #fff;
    border-radius: 50%;
    -webkit-animation: chr-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: chr-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $chr-active-blue transparent transparent transparent;
  }

  .chr-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s;
  }

  .chr-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .chr-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
  }
}

@keyframes chr-ring {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
